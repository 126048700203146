import { Avatar, IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material';
import CustomAvatarComponents from 'components/ImageDocument/AvatarComponents';
import { IconTransactionView } from 'constants/icons';

import Avatar2 from 'assets/images/image-placeholder.png';
import { firstLetterUppercase, formatDate } from 'utils/commonHelpers';
import { getTransactionStatus } from 'ui-component/common';
import { useNavigate } from 'react-router-dom';

const AppUserTableContainer = ({ row, index }: { row: any; index: any }) => {
  const navigate = useNavigate();

  const handleView = (e, row) => {
    localStorage.setItem('transaction', JSON.stringify(row));
    localStorage.setItem('transactionType', 'Clients');
    navigate('/transaction-management/detail');
  };
  return (
    <>
      <TableRow key={index}>
        <TableCell align="left" sx={{ whiteSpace: 'nowrap', width: 280, maxWidth: '100%' }}>
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            {row?.client?.profileImage ? (
              <CustomAvatarComponents
                s3Key={`profiles/${row?.clientId}/${row?.client?.profileImage}`}
                style={{
                  background: '#fff',
                  border: '1px solid #90CAF9',
                  boxShadow: '2px 2px 2px 0px rgba(0, 0, 0, 0.16)',
                  width: '60px',
                  height: '60px'
                }}
              />
            ) : (
              <Avatar
                sx={{
                  background: '#fff',
                  border: '1px solid #90CAF9',
                  boxShadow: '2px 2px 2px 0px rgba(0, 0, 0, 0.16)',
                  width: '60px',
                  height: '60px'
                }}
                alt="User 1"
                src={Avatar2}
              />
            )}
            <Typography align="left" variant="subtitle1" component="div">
              {`${row?.client?.firstName ? row?.client?.firstName : 'N/A'} ${row?.client?.lastName ? row?.client?.lastName : ''}`}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
          {row?.transactionType
            ? row?.transactionType == 'hicap'
              ? 'Refund'
              : firstLetterUppercase(row?.transactionType)
            : 'N/A'}
        </TableCell>
        <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
          {row?.createdAt ? formatDate(row?.createdAt) : 'N/A'}
        </TableCell>

        <TableCell className="appUserTableAddress" align="left" sx={{ whiteSpace: 'nowrap' }}>
          {row?.amount ? `AUD ${row?.amount}` : 'N/A'}
        </TableCell>

        <TableCell className="appUserTableAddress" align="left" sx={{ whiteSpace: 'nowrap' }}>
          {row?.status ? getTransactionStatus(row?.status) : 'N/A'}
        </TableCell>
        <TableCell align="left" sx={{ pr: 3 }}>
          <IconButton
            sx={{
              minWidth: '0',
              minHeight: '0',
              border: 'none',
              padding: '0'
            }}
            onClick={(e) => handleView(e, row)}
          >
            <IconTransactionView />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
export default AppUserTableContainer;
